export const filters = {
  ingredient: {
    id: 'ingredient',
    name: 'Ingredients',
  },
  mealType: {
    id: 'mealType',
    name: 'Meal Type',
  },
};

export const actionTypes = {
  ingredient: filters.ingredient.id,
  mealType: filters.mealType.id,
  lookupList: 'lookupList',
  recipes: 'recipes',
};

const initialState = {
  recipes: [],
  ingredient: [],
  mealType: [],
  lookupList: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.recipes:
      return {
        ...state,
        recipes: action.payload,
      };
    case actionTypes.ingredient:
      return {
        ...state,
        ingredient: action.payload,
      };
    case actionTypes.mealType:
      return {
        ...state,
        mealType: action.payload,
      };
    case actionTypes.lookupList:
      return {
        ...state,
        lookupList: action.payload,
      };
    case actionTypes.isModalOpen:
      return {
        ...state,
        isModalOpen: action.payload,
      };
    default:
      throw new Error(); // Throw error if non of the cases are met
  }
};

export const RecipeReducer = {
  initialState,
  reducer,
};
