import React from 'react';
import { Link } from 'gatsby';

import './index.scss';

export const Card = ({ recipe }) => {
  return (
    <Link to={`/nutritiondirect/recipes/${recipe.slug}`}>
      <div className="box box-container">
        <div className="box-content">
          <img src={recipe.thumbnail.url} alt={recipe.slug} />
          <div className="recipe-title">{recipe.title}</div>
        </div>
      </div>
    </Link>
  );
};
