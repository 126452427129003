import React, { useState, useEffect, useContext } from 'react';
import Loader from 'react-loader-spinner';

import { SanityGetAllRecipes } from 'libs/RecipeService';
import { Context } from 'providers/recipeProvider';
import { actionTypes } from 'libs/RecipeReducer';
import { FiltersConatiner } from 'components/Page/Recipe/Filter';
import { Card } from 'components/Page/Recipe/Card';

import './index.scss';

export const RecipeContainer = props => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [localRecipes, setLocalRecipes] = useState(null);
  const { recipeState, dispatchRecipeState } = useContext(Context);

  useEffect(() => {
    setHasLoaded(false);
    (async () => {
      try {
        const result = await SanityGetAllRecipes.getAllRecipes();
        setLocalRecipes(Object.values(result?.data?.recipes));
        await dispatchRecipeState({
          type: actionTypes.recipes,
          payload: result?.data?.recipes,
        });
        await dispatchRecipeState({
          type: actionTypes.lookupList,
          payload: {
            ingredient: result?.data?.ingredient,
            mealType: result?.data?.mealType,
          },
        });
        await setHasLoaded(true);
      } catch (e) {
        console.log('error', e);
      }
    })();
  }, []);

  useEffect(() => {
    let filteredRecipes = Object.values(recipeState?.recipes) || [];
    const selectedIngredient =
      recipeState?.ingredient.map(ingredient => ingredient.value) || [];
    const selectedMealType =
      recipeState?.mealType.map(mealType => mealType.value) || [];

    if (selectedIngredient?.length === 0 && selectedMealType?.length === 0) {
      setLocalRecipes(filteredRecipes);
    } else {
      if (Array.isArray(filteredRecipes)) {
        if (selectedIngredient?.length > 0) {
          filteredRecipes = filteredRecipes.filter(recipe => {
            const receipeIngredient = recipe?.ingredient;
            const includeIngredient = selectedIngredient.filter(ing => {
              return receipeIngredient.includes(ing);
            });
            if (includeIngredient.length > 0) {
              return recipe;
            }
          });
        }

        if (selectedMealType?.length > 0) {
          filteredRecipes = filteredRecipes.filter(recipe => {
            const receipeMealType = recipe?.mealType;
            const includeMealType = selectedMealType.filter(mealType => {
              return receipeMealType.includes(mealType);
            });
            if (includeMealType.length > 0) {
              return recipe;
            }
          });
        }
        setLocalRecipes(filteredRecipes);
      }
    }
  }, [recipeState]);

  const renderFilters = () => {
    const lookupList = recipeState?.lookupList;
    return (
      <div className="filter-container">
        {Object.keys(lookupList).map(key => {
          const selectOptions = Object.values(lookupList[key]);
          return (
            <FiltersConatiner
              options={selectOptions}
              key={key}
              id={key}
              label={key}
            />
          );
        })}
      </div>
    );
  };

  const renderRecipeContent = () => {
    const noResultContainer = () => {
      return <div>No result found.</div>;
    };

    const renderRecipe = () => {
      const numberOfRecipePerRow = 4;
      return (
        <div className="recipe-container">
          <div className="recipe-section">
            {localRecipes.map((recipe, index) => {
              const receipeClasses =
                (index + 1) % numberOfRecipePerRow === 0
                  ? `recipe-card no-space-right`
                  : `recipe-card card-space-right`;
              return (
                <div className={receipeClasses} index={index} key={index}>
                  <Card recipe={recipe} />
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    return Array.isArray(localRecipes) && localRecipes.length > 0
      ? renderRecipe()
      : noResultContainer();
  };

  const renderLoading = () => {
    return (
      <div className="">
        <div className="">
          <Loader type="Oval" height={100} width={100} color="purple" />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <>
        {renderFilters()}
        {renderRecipeContent()}
      </>
    );
  };

  const renderContent = () => {
    return hasLoaded ? <>{renderBody()}</> : renderLoading();
  };

  return <>{renderContent()}</>;
};
