import React, { useState, useEffect, useContext } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';

import { Layout, Seo } from 'components';
import { appContext } from 'providers/appProvider';
import SourceEmitter from 'libs/emitter';

import { RecipeContainer } from 'components/Page/Recipe';
import Provider from 'providers/recipeProvider';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';

const Recipes = props => {
  let Subscription = null;
  const [hasLoaded, setHasLoaded] = useState(false);
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionPatient;
  // const metaData = appConfigs?.metaData?.nutritionPatient;

  const triggerHashChange = () => {
    const currentHash = window?.location.hash.substring(
      1,
      window?.location.hash.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    setHasLoaded(true);
    window.addEventListener('hashchange', triggerHashChange, false);

    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });

    return () => {
      // eslint-disable-next-line no-unused-expressions
      window?.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);
  const rendertitle = () => {
    return (
      <h2 className="strong big-h2">
        HEAT THINGS UP WITH HEALTHY AND DELICIOUS RECIPES
      </h2>
    );
  };

  const pageTitle = 'NUTRITION DIRECT™ | Recipe Library';
  const pageDescription =
    'Watch recipe videos and learn about Nutrition Direct™';

  // const renderPageHeroContent = () => {
  //   return (
  //     <ScrollableAnchor id="why_nutrition_direct">
  //       <div>
  //         <HeadlineBar blue>EASY-TO-PREPARE DELICIOUS RECIPES</HeadlineBar>
  //         <img
  //           src={nutritionHero}
  //           className="hero-image"
  //           alt="why-nutrition-direct-block-hero why-nutrition-direct-hero"
  //         />
  //       </div>
  //     </ScrollableAnchor>
  //   );
  // };

  // const renderBodyText = () => {
  //   return (
  //     <p className="hero-body-text pt-10">
  //       Eating healthy just got easier! Dig into the recipes below to see how
  //       easy it is to make these delicious dishes:
  //     </p>
  //   );
  // };

  const renderPage = () => {
    return (
      hasLoaded && (
        <div id="nutrition-patient-recipe">
          <ContentBlock>
            <ColorBlock white>
              {rendertitle()}
              {/* {renderPageHeroContent()}
            {renderBodyText()} */}
              <RecipeContainer />
              <p className="footnote asterik">
                This recipe was provided by Froedtert & the Medical College of
                Wisconsin Weight Loss/Bariatric Surgery Program and reviewed by
                nutritionists for optimal nutritional content. Froedtert
                Memorial Lutheran Hospital/Medical College Wisconsin’s Bariatric
                Surgery Program as well as its surgeons have been designated a
                “Comprehensive Center for Bariatric Surgery” by the American
                Society of Metabolic and Bariatric Surgery. 2016 Froedtert & the
                Medical College of Wisconsin. Reprinted with permission.
              </p>
            </ColorBlock>
          </ContentBlock>
          <ScrollableAnchor id="isi_anchor">
            <div />
          </ScrollableAnchor>
        </div>
      )
    );
  };
  return (
    <Layout indication={indication} className="nutrition-patient-recipe">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <Provider>
        <div className="body-content-container">
          <div className="body-content gutter-all">{renderPage()}</div>
        </div>
      </Provider>
    </Layout>
  );
};

export default Recipes;
