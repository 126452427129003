import React, { useContext } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Context } from 'providers/recipeProvider';
import { actionTypes, filters } from 'libs/RecipeReducer';

import './index.scss';

export const FiltersConatiner = ({ options, id, label }) => {
  const { dispatchRecipeState } = useContext(Context);
  const displayLabel = filters?.[label]?.name;

  const handleChange = options => {
    dispatchRecipeState({ type: actionTypes[label], payload: options });
  };

  return (
    <div className="filter-block">
      <div className="filter-label">{displayLabel}:</div>
      <Select
        options={options}
        isMulti
        component={makeAnimated()}
        onChange={selectedOptions => handleChange(selectedOptions)}
        className="filter-dropdown"
      />
    </div>
  );
};
