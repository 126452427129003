import React, { useReducer, createContext } from 'react';
import { RecipeReducer } from 'libs/RecipeReducer';

export const Context = createContext();

const Provider = props => {
  const [recipeState, dispatchRecipeState] = useReducer(
    RecipeReducer.reducer,
    RecipeReducer.initialState,
  );

  return (
    <Context.Provider value={{ recipeState, dispatchRecipeState }}>
      {props.children}
    </Context.Provider>
  );
};

export default Provider;
