import { envName, getEnv } from './utilityHelper';
import axios from 'axios';

const getRecipesAPIEndpoint =
  getEnv() === envName.nonProd
    ? '/get-recipes-qa'
    : 'https://elastic-jungle-a5655b.netlify.app/get-recipes';

const getAllRecipes = async () => await axios.get(getRecipesAPIEndpoint);

export const SanityGetAllRecipes = {
  getAllRecipes,
};
